<template>
  <form-holder />
</template>

<script>
import FormHolder from '../components/FormHolder.vue'

  export default {
    name: 'Inscription',

    components: {
      FormHolder
    },
  }
</script>
