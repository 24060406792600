<template>
  <div>
    <div class="text-body-1 my-4">
      Chez LéoLearning, nous avons deux types de postes : <br /><br />
      <span
        >Les <b>tuteurs</b>, ce sont eux qui donnent des cours, ils ont
        généralement des facilités dans une ou plusieurs matières (ils ont aussi
        des difficultés dans d'autres !)</span
      ><br />
      <div class="mt-2">
        L'<b>administration</b>, le travail de l'ombre : l'organisation des
        séances de tutorat, la communication, la Vie Associative...
      </div>
      <br />
    </div>

    <v-card class="card" elevation="2">
      <v-container>
        <v-row>
          <v-col cols="2"> </v-col>
          <v-col>
            <v-select
              v-model="selectJob"
              :items="jobs"
              label="Poste"
              @input="handleInput($event, 'post')"
            ></v-select>
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
        <div v-if="selectJob === 'Tuteur'">
          <v-row>
            <v-col> </v-col>
            <v-col>
              <v-img
                :src="require('../../assets/FicheDePoste/Fiche-tuteur.jpg')"
                contain
                max-height="650"
                max-width="500"
              ></v-img>
            </v-col>
            <v-col> </v-col>
          </v-row>
          <v-row>
            <v-col>
              Dis nous dans quelles matières tu es à l'aise (ne t'en fais pas,
              ce n'est pas définitif) !
              <v-select
                class="mt-4 mx-md-6"
                label="Matière"
                outlined
                multiple
                chips
                :items="materials[school][year]"
                :rules="materialRule"
                @input="handleInput($event, 'materials')"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col>
              <p class="text-body-1">
                Dans l'administration nous avons 3 pôle, un pôle organisation
                (responsable de l'organisation des cours de tutorat), un pôle
                communication et enfin un pôle vie associative afin de créer un
                cadre sympathique. <br />
                Sélectionne le pôle qui d'intéresse et clic sur l'icône
                <v-icon class="fas fa-info-circle"></v-icon> pour en savoir plus
                sur le poste.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"> </v-col>
            <v-col>
              <v-select
                v-model="selectPole"
                :rules="poleRule"
                :items="pole"
                label="Pôle"
              ></v-select>
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
          <v-row
            v-if="selectPole == 'Pôle organisation'"
            class="px-md-8 reduce-spacing"
          >
            <v-col>
              <v-radio-group
                mandatory
                @change="handleInput($event, 'post')"
                class="reduce-spacing"
              >
                <div v-for="elem in listPostAdmin[school]" :key="elem.id">
                  <v-radio
                    class="d-inline"
                    :label="elem.value"
                    :value="elem.value"
                  ></v-radio>
                  <span class="ml-2">
                    <v-dialog v-model="elem.dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          class="fas fa-info-circle"
                        ></v-icon>
                      </template>
                      <v-card>
                        <v-img :src="elem.link" contain></v-img>
                      </v-card>
                    </v-dialog>
                  </span>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="selectPole == 'Pôle communication'"
            class="px-md-8 reduce-spacing"
          >
            <v-col>
              <v-radio-group
                mandatory
                @change="handleInput($event, 'post')"
                class="reduce-spacing"
              >
                <div v-for="elem in listPostCom" :key="elem.id">
                  <v-radio
                    class="d-inline"
                    :label="elem.value"
                    :value="elem.value"
                  ></v-radio>
                  <span class="ml-2">
                    <v-dialog v-model="elem.dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          class="fas fa-info-circle"
                        ></v-icon>
                      </template>
                      <v-card>
                        <v-img :src="elem.link" contain></v-img>
                      </v-card>
                    </v-dialog>
                  </span>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="selectPole == 'Pôle vie associative'"
            class="px-md-8 reduce-spacing"
          >
            <v-col>
              <v-radio-group
                mandatory
                @change="handleInput($event, 'post')"
                class="reduce-spacing"
              >
                <div v-for="elem in listPostVA" :key="elem.id">
                  <v-radio
                    class="d-inline"
                    :label="elem.value"
                    :value="elem.value"
                  ></v-radio>
                  <span class="ml-2">
                    <v-dialog v-model="elem.dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          class="fas fa-info-circle"
                        ></v-icon>
                      </template>
                      <v-card>
                        <v-img :src="elem.link" contain></v-img>
                      </v-card>
                    </v-dialog>
                  </span>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
        <v-row class="reduce-spacing">
          <v-col>
            <p>
              Quelles seront les activité que tu voudrais faire une fois dans
              l'asso ?
            </p>
            <v-select
              class="mt-4 mx-md-6"
              label="Activité assos"
              outlined
              multiple
              chips
              :items="activiteAsso"
              @input="handleInput($event, 'activity_asso')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="px-md-8 reduce-spacing">
          <v-col>
            <p>Une petite blague ?</p>
            <v-text-field
              type="text"
              label="Blague"
              placeholder="Blague"
              @input="handleInput($event, 'joke')"
              name="blagueInput"
              id="blagueInput"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-md-8 reduce-spacing">
          <v-col>
            <p>Si tu as une question c'est le moment !</p>
            <v-textarea
              type="text"
              label="Question"
              placeholder="Question"
              @input="handleInput($event, 'other')"
              name="questionInput"
              id="questionInput"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FormPostChoice",
  props: ["school", "year"],
  data: () => ({
    selectJob: "Tuteur",
    dialog: false,
    jobs: ["Tuteur"], //, "Administrateur"
    selectPole: "",
    pole: ["Pôle organisation", "Pôle communication", "Pôle vie associative"],
    materials: {
      ESILV: {
        A1: ["Mathématiques", "Physique", "Informatique"],
        A2: ["Mathématiques", "Physique", "Informatique"],
        A3: ["Mathématiques", "Physique", "Informatique"],
        A4: ["Mathématiques", "Physique", "Informatique"],
        A5: ["Mathématiques", "Physique", "Informatique"],
      },
      EMLV: {
        A1: [
          "English track",
          "Codage",
          "Droit",
          "Marketing",
          "Finance",
          "Innovation",
          "Management",
          "Excel",
          "Comptabilité",
          "Fondamentaux de l'économie",
          "Etude de marché",
          "Vente",
        ],
        A2: [
          "English track",
          "Codage",
          "Droit",
          "Marketing",
          "Finance",
          "Innovation",
          "Management",
          "Excel",
          "Comptabilité",
          "Fondamentaux de l'économie",
          "Etude de marché",
          "Vente",
        ],
        A3: [
          "English track",
          "Codage",
          "Droit",
          "Marketing",
          "Finance",
          "Innovation",
          "Management",
          "Excel",
          "Comptabilité",
          "Contrôle de gestion",
          "Fiscalité",
          "Analyse financière",
          "Fondamentaux de l'économie",
          "Etude de marché",
          "Vente",
        ],
        A4: [
          "English track",
          "Codage",
          "Droit",
          "Marketing",
          "Finance",
          "Innovation",
          "Management",
          "Excel",
          "Comptabilité",
          "Contrôle de gestion",
          "Fiscalité",
          "Analyse financière",
          "Fondamentaux de l'économie",
          "Etude de marché",
          "Vente",
        ],
        A5: [
          "English track",
          "Codage",
          "Droit",
          "Marketing",
          "Finance",
          "Innovation",
          "Management",
          "Excel",
          "Comptabilité",
          "Contrôle de gestion",
          "Fiscalité",
          "Analyse financière",
          "Fondamentaux de l'économie",
          "Etude de marché",
          "Vente",
        ],
      },
      IIM: {
        A1: [
          "Jeux video",
          "Creation & design",
          "Développement Web",
          "3D",
          "Communication digitale",
        ],
        A2: [
          "Jeux video",
          "Creation & design",
          "Développement Web",
          "3D",
          "Communication digitale",
        ],
        A3: [
          "Jeux video",
          "Creation & design",
          "Développement Web",
          "3D",
          "Communication digitale",
        ],
        A4: [
          "Jeux video",
          "Creation & design",
          "Développement Web",
          "3D",
          "Communication digitale",
        ],
        A5: [
          "Jeux video",
          "Creation & design",
          "Développement Web",
          "3D",
          "Communication digitale",
        ],
      },
    },
    listPostCom: [
      {
        id: 3,
        value: "Responsable communication",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche responsable d'équipe communication.jpg"),
      },
      {
        id: 4,
        value: "Chargé de photo/vidéo",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche chargé de photo et vidéo.jpg"),
      },
      {
        id: 5,
        value: "Community manager",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche community manager.jpg"),
      },
      {
        id: 6,
        value: "Chargé d'animation visuelle",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche chargé d'animation visuelle.jpg"),
      },
    ],
    listPostVA: [
      {
        id: 9,
        value: "Responsable vie associative",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche responsable vie associative.jpg"),
      },
      {
        id: 10,
        value: "Chargé évènement hebdomadaire",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche chargé organisation évènements Hebdomadaire.jpg"),
      },
      {
        id: 11,
        value: "Chargé grand évènement",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche chargé organisation grands évènements.jpg"),
      },
      {
        id: 12,
        value: "Chargé d'integration",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche chargé d'intégration.jpg"),
      },
      {
        id: 13,
        value: "Chargé de communication interne",
        dialog: false,
        link: require("../../assets/FicheDePoste/Fiche chargé de communication vie associative.jpg"),
      },
    ],
    listPostAdmin: {
      ESILV: [
        {
          id: 0,
          value: "Responsable organisation ESILV",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche responsable d'organisation du tutorat.jpg"),
        },
        {
          id: 1,
          value: "Chargé d'organisation ESILV",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche chargé d'organisation du tutorat.jpg"),
        },
        {
          id: 2,
          value: "Chargé data ESILV",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche chargé data.jpg"),
        },
      ],
      EMLV: [
        {
          id: 0,
          value: "Responsable organisation EMLV",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche responsable d'organisation du tutorat.jpg"),
        },
        {
          id: 1,
          value: "Chargé d'organisation EMLV",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche chargé d'organisation du tutorat.jpg"),
        },
        {
          id: 2,
          value: "Chargé data EMLV",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche chargé data.jpg"),
        },
      ],
      IIM: [
        {
          id: 0,
          value: "Responsable organisation IIM",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche responsable d'organisation du tutorat.jpg"),
        },
        {
          id: 1,
          value: "Chargé d'organisation IIM",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche chargé d'organisation du tutorat.jpg"),
        },
        {
          id: 2,
          value: "Chargé data IIM",
          dialog: false,
          link: require("../../assets/FicheDePoste/Fiche chargé data.jpg"),
        },
      ],
    },
    activiteAsso: [
      "Sortir boire des bières / Restaurant 🍻",
      "Faire de sorties culturelles (musée, expo,...)",
      "Bowling, accrobranche, lazer game, cinéma, karting",
      "Jeux en ligne",
      "Autre",
      "Rien du tout",
    ],
    poleRule: [(v) => !!v || "Le pôle est requis"],
    materialRule: [(v) => v.length > 0 || "Au moins une matière est requise"],
  }),
  methods: {
    handleInput(event, inputName) {
      if (event == "Administrateur") {
        this.$emit("input", { value: [], inputField: "materials" });
      }
      this.$emit("input", { value: event, inputField: inputName });
    },
    alert(inputField) {
      alert(inputField);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reduce-spacing {
  margin-top: 0;
}
</style>
