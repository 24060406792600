<template>
  <v-container>
    <v-row class="text-center mt-2">
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
      <v-col>
          <p class="text-h6">
              Commence par te présenter : 👇
          </p>
          <v-form v-on:submit.prevent="submit()" ref="form">
            <form-personnal-information @input="fieldHandler"/>
            <form-post-choice v-if="applicant.school != '' && applicant.year != ''" :school="applicant.school" :year="applicant.year" @input="fieldHandler"/>
            <v-alert v-if="highlightError == true" dense text outlined type="error" class="mt-6">
                <p class="text-h6 pt-4">
                  Malheureusement, une erreur est arrivé, n'hésite pas à ré-essayer plus tard.<br>Si le problème persiste, contacte leolearning@devinci.fr ou la page <a href="https://facebook.com/LeoLearningPULV">LeoLearning</a> sur Facebook 
                </p>
            </v-alert>
            <v-alert v-if="highlightErrorMultipleSubmition == true" dense text outlined type="error" class="mt-6">
                <p class="text-h6 pt-4">
                  Malheureusement, nous n'avons pas pu envoyer ton formulaire car tu as déjà fais deux demandes.<br>Si tu souhaites modifier ton inscription, contacte leolearning@devinci.fr ou la page <a href="https://facebook.com/LeoLearningPULV">LeoLearning</a> sur Facebook 
                </p>
            </v-alert>
            <v-btn
              elevation="2"
              type="submit"
              color="primary"
              class="mt-3"
            >ENVOYER</v-btn>
          </v-form>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormPersonnalInformation from './FormComponent/FormPersonnalInformation.vue'
import FormPostChoice from './FormComponent/FormPostChoice.vue'

export default {
  components: { FormPersonnalInformation, FormPostChoice },
  name: 'FormHolder',
  props: {
  },
  data: () => ({
    personalInformationValid: false,
    highlightError: false,
    highlightErrorMultipleSubmition: false,
    applicant:{
      lastname: '',
      firstname: '',
      email: '',
      school: '',
      year: '',
      post: 'Tuteur',
      materials: [],
      activity_asso: [],
      joke: "",
      other: ""
    }
  }),
  methods: {
    submit: function(){
        if(this.$refs.form.validate()){
            this.$http.post("https://api-recrutement.leolearning.fr/applicants", this.applicant).then(response => {
              if(response.status == 201){
                this.$router.push({ path: 'succes', query: { mail: this.applicant.email }}) 
              }
            }).catch(err => {
              if(err.response.data.message == "Applicant has already made 2 applications"){
                this.highlightErrorMultipleSubmition = true
                this.highlightError = false
              }
              else{
                this.highlightErrorMultipleSubmition = false
                this.highlightError = true
              }
            });
        }
        else{
          window.scrollTo(0,0);
        }
    },
    fieldHandler(field){
        switch(field.inputField){
            case 'lastname':
                this.applicant.lastname = field.value
                break
            case 'firstname':
                this.applicant.firstname = field.value
                break
            case 'email':
                this.applicant.email = field.value
                break
            case 'school':
                this.applicant.school = field.value
                break
            case 'year':
                this.applicant.year = field.value
                break
            case 'post':
                this.applicant.post = field.value
                break
            case 'materials':
                this.applicant.materials = field.value
                break
            case 'activity_asso':
                this.applicant.activity_asso = field.value
                break
            case 'joke':
                this.applicant.joke = field.value
                break
            case 'other':
                this.applicant.other = field.value
                break
        }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
