<template>
    <v-card class="card" elevation="2">
        <v-container>
            <v-row class="mx-md-4">
                <v-col>
                    <v-text-field type="text" label="Prénom" placeholder="Prénom" :rules="nameRules" @input="handleInput($event, 'firstname')" name="firstnameInput" id="firstnameInput"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field type="text" label="Nom" placeholder="Nom" :rules="nameRules" @input="handleInput($event, 'lastname')" name="lastnameInput" id="lastnameInput"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mx-md-4">
                <v-col cols="5">
                    <v-text-field type="email" label="Email devinci" placeholder="Email devinci" :rules="emailRule" @input="handleInput($event, 'email')" name="emailInput" id="emailInput"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-select :items="schools" label="École" :rules="schoolRule" @input="handleInput($event, 'school')"></v-select>
                </v-col>
                <v-col cols="3">
                    <v-select :items="years" label="Année" :rules="yearRule" @input="handleInput($event, 'year')"></v-select>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
  name: 'FormPersonnalInformation',
  props: {
  },
  data: () => ({
      schools: ['ESILV', 'EMLV', 'IIM'],
      years: ['A1', 'A2', 'A3', 'A4', 'A5'],
      nameRules: [
        v => !!v || "Le nom/prénom est requis"
      ],
      emailRule: [
        v => !!v || "L'e-mail est requis",
        v => /.+@edu.devinci.fr/.test(v) || "Adresse email devinci doit être valide",
      ],
      schoolRule: [
        v => !!v || "L'école est requise" 
      ],
      yearRule: [
        v => !!v || "L'année est requise"   
      ]
  }),
  methods: {
    handleInput(event, inputName) {
      this.$emit("input", {value: event, inputField: inputName});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
